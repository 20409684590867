import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { FooterWrapper, SocialLinksDiv } from "./Footer.styles"

export const Footer = () => {
  return (
    <FooterWrapper>
      <SocialLinksDiv>
        <a href="https://www.facebook.com/placesunbound" target="_blank">
          <StaticImage
            src={"../../images/facebook-logo.webp"}
            layout="fixed"
            alt="facebook-logo"
            height={41}
          />
        </a>
        <a href="https://www.instagram.com/placesunbound/" target="_blank">
          <StaticImage
            src={"../../images/insta-logo.webp"}
            layout="fixed"
            alt="instagram-logo"
            height={41}
          />
        </a>
      </SocialLinksDiv>
      <p>
        © 2021 by Places Unbound Architects | Places Unbound Architectural
        Design and Research Studio <br />
        Marubahil | Kathmandu, Nepal | info@puastudio.com
      </p>
    </FooterWrapper>
  )
}
