import React from "react"
import { ThemeProvider } from "styled-components"

const theme = {
  colors: {
    white: "#fffdf9",
    black: "#363230",
    positive1: "#b3c86a", // green
    positive2: "#6bb4c9", // light blue
    negative: "#e77e73",
    accentColorDark: "#d0b97b",
    accentColor: "#114c93",
  },
  fonts: ["Tajawal", "Arial", "Helvetica", "sans-serif"],
  fontSizes: {
    small: "1em",
    medium: "2em",
    large: "3em",
  },
}

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
)

export default Theme
