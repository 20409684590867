import React, { useState } from "react"
import Theme from "../../Theme"
import { Footer } from "../Footer/Footer"
import { Header } from "../Header"
import OverlayMenu from "../OverlayMenu"

import { GlobalStyles, Primary } from "./Layout.styles"

export const Layout: React.FC = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const handleOverlayMenu = () => setMenuOpen(prev => !prev)

  return (
    <Theme>
      <GlobalStyles />
      <OverlayMenu menuOpen={menuOpen} callback={handleOverlayMenu} />
      <Header handleOverlayMenu={handleOverlayMenu} />
      <Primary>{children}</Primary>
      <Footer />
    </Theme>
  )
}
