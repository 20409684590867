import styled from 'styled-components';

export const HamburgerButton = styled.div`
display: flex;
padding: 0 0 10px 10px;
  .menu-icon {
    width: 40px;
    height: 40px;
    padding-left: 10px;
    @media (min-width: 992px) {
      display: none;
    }
  }

  .menu-icon-image {
    z-index: 100000;
    width: 40px;
    padding: 0;
    height: 40px;
    cursor: pointer;
    transition: all 0.4s ease;
    :hover {
      transform: scale(1.2);
    }
    @media (min-width: 992px) {
      display: none;
    }
  }
`;