import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import { HamburgerButton } from "./Hamburger.styles"

const Hamburger = ({ handleOverlayMenu }) => (
  <HamburgerButton onClick={handleOverlayMenu}>
    <StaticImage
      imgClassName="menu-icon-image"
      className="menu-icon"
      src={"../../images/menu-icon.svg"}
      alt={"Places Unbound"}
      placeholder="blurred"
    />
  </HamburgerButton>
)

export default Hamburger
