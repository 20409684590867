import styled from "styled-components"

export const FooterWrapper = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts[0]};
  text-align: center;
  height: 200px;

  p {
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.colors.white};
    font-size: 15px;
  }

  a {
    color: #fff;
    text-decoration: none;
  }
`

export const SocialLinksDiv = styled.div`
  display: flex;
  flex-direction: row;
`
