import styled from "styled-components";

export const Wrapper = styled.header`
  background: ${({ theme }) => theme.colors.black};
  height: 120px;
  // border-bottom: 1px solid #e7e7e7;
  position: fixed;
  width: 100%;
  z-index: 10000;
  @media (max-width: 992px) {
    height: 110px;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-end;
  max-width: 1180px;
  margin: 0 auto;
  padding: 5px;

  img {
    margin: 0;
    padding: 12px 0 0 0;
  }
`;

export const InnerContent = styled.div`
  display: flex;
  flex: 1;
  // border-bottom: 8px solid black;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  margin: 0 auto;
  padding: 0;

  @media (max-width: 992px) {
    justify-content: space-between;
  }

  .header-nav {
    padding-right: 10px;
  }
`

export const BrandDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: row;

`;

export const BrandName = styled.div`
  padding-left: 10px;
  p {
    margin: 0;
    padding: 0;
    padding-top: 2px;
    line-height: 1;
    font-family: ${({ theme }) => theme.fonts[0]};
    font-size: 40px;
    font-weight: 700;
    color: ${props => props.theme.colors.white};
    text-align: left;
    letter-spacing: 10px;

    @media (max-width: 992px) {
      font-size: 25px;
    }
  }
`;

export const BrandLogo = styled.div`
  .brand-image {
    height: 100px;
    width: 100px;

    img {
      height: 100px;
      width: 100px;
      padding: 0;
    }

    @media (max-width: 992px) {
      height: 80px;
      width: 80px;
      padding: 0;

      img {
        height: 80px;
        width: 80px;
        padding-left: 5px;
        padding-top: 5px;
      }
  }
  }
`