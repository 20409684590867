import styled from 'styled-components';

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  opacity: ${props => (props.menuOpen ? '1' : '0')};
  transform: ${props =>
    props.menuOpen ? 'translateX(0%)' : 'translateX(-100%)'};
  z-index: 100000;
  background-color: ${props => props.theme.colors.black};
  left: 0px;
  padding: 0px;
  transition: all 0.3s ease;
  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: ${props => props.theme.colors.black};
    width: 100%;
    height: 100%;
    color: #fff;
    padding: 50px;
    .invertedLogo {
      max-width: 200px;
      margin: 0 0 80px 0;
    }
    .overlayMenu {
      text-align: center;
      list-style-type: none;
      margin-bottm: 20px;
      li {
        margin: 0 0 16px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        ul {
          margin: 16px 0 0;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          list-style-type: none;

          li a {
            font-size: 1.4em;
          }
        }

      }
      li.overlayActive {
        color: #ee2562;
      }
      a {
        font-family: ${({ theme }) => theme.fonts[0]};
        font-size: 1.7rem;
        color: ${({ theme }) => theme.colors.white};
        transition: all 0.3s ease;
        text-decoration: none;
        :hover {
          color: ${({ theme }) => theme.colors.accentColorDark};
        }
      }
    }
  }
  .closeButton {
    position: absolute;
    top: 40px;
    right: 70px;
    color: ${({ theme }) => theme.colors.white};
    width: 8px;
    height: 8px;
    cursor: pointer;
    transition: all 1s ease;
    outline: none;
  }

`;