import { useStaticQuery, graphql } from 'gatsby';

export const useMenuQuery = () => {
    const data = useStaticQuery(graphql`
    query HeaderQuery {
        wpMenu(name: {eq: "mainMenu"}) {
            menuItems {
            nodes {
                id
                parentId
                label
                url
                childItems {
                nodes {
                    label
                    title
                    url
                    id
                }
                }
            }
            }
        }
        site {
            siteMetadata {
            title
            }
        }
    }`)
    return data;
}
