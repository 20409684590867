import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import { useMenuQuery } from "../../hooks/useMenuQuery"
import Hamburger from "../Hamburger"
import { Navigation } from "../Navigation"

import {
  BrandDiv,
  BrandLogo,
  BrandName,
  Content,
  InnerContent,
  Wrapper,
} from "./Header.styles"

type HeaderProps = {
  handleOverlayMenu: () => void
}

export const Header = ({ handleOverlayMenu }: HeaderProps) => {
  const data = useMenuQuery()

  return (
    <Wrapper>
      <Content>
        <BrandLogo>
          <StaticImage
            className="brand-image"
            imgClassName="brand-image"
            src={"../../images/logo-no-bg.webp"}
            alt={"Places Unbound"}
            placeholder="blurred"
          />
        </BrandLogo>
        <InnerContent>
          <Link to="/">
            <BrandDiv>
              <BrandName>
                <p>places</p>
                <p>unbound</p>
              </BrandName>
            </BrandDiv>
          </Link>
          <div className="header-nav">
            <Navigation menu={data?.wpMenu?.menuItems?.nodes || []} />
            <Hamburger handleOverlayMenu={handleOverlayMenu} />
          </div>
        </InnerContent>
      </Content>
    </Wrapper>
  )
}
