import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { useMenuQuery } from "../../hooks/useMenuQuery"
import { Overlay } from "./OverlayMenu.styles"

const OverlayMenu = ({ menuOpen, callback }) => {
  const { wpMenu } = useMenuQuery()

  return (
    <Overlay menuOpen={menuOpen}>
      <div className="inner">
        <StaticImage
          imgStyle={{ height: 120 }}
          src={"../../images/logo-no-bg.webp"}
          alt={"Places Unbound"}
          placeholder="blurred"
          layout="fixed"
          height={120}
          width={120}
        />
        <ul className="overlayMenu">
          {wpMenu?.menuItems?.nodes.map(item =>
            !item.parentId ? (
              <li key={item.id}>
                <Link to={item.url} activeClassName="overlayActive">
                  {item.label}
                  {item.childItems.nodes.length != 0 && <span>&#8964;</span>}
                </Link>
                {item.childItems.nodes.length !== 0 ? (
                  <ul>
                    {item.childItems.nodes.map(childItem => (
                      <li key={childItem.id}>
                        <Link
                          to={childItem.url}
                          activeClassName="overlayActive"
                        >
                          {childItem.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </li>
            ) : null,
          )}
          <li>
            <Link to="/contact-us" activeClassName="nav-active">
              Contact Us
            </Link>
          </li>
        </ul>
        <div
          className="closeButton"
          onClick={callback}
          role="button"
          //   tabIndex="0"
          onKeyDown={callback}
        >
          <StaticImage
            imgStyle={{ height: 40 }}
            src={"../../images/close_btn.svg"}
            alt={"Places Unbound"}
            placeholder="blurred"
            layout="fixed"
            height={40}
            width={40}
          />
        </div>
      </div>
    </Overlay>
  )
}

export default OverlayMenu
