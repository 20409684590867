import React from "react"
import { Link } from "gatsby"

import { NavigationWrapper } from "./Navigation.styles"

type NavigationProps = {
  menu: any
}

export const Navigation = ({ menu }: NavigationProps) => {
  return (
    <NavigationWrapper>
      <ul>
        {menu.map(mainItem =>
          !mainItem.parentId ? (
            <li key={mainItem.id}>
              <Link
                to={mainItem.url}
                activeClassName="nav-active"
                partiallyActive
              >
                {mainItem.label}
                {mainItem.childItems.nodes.length !== 0 && <div>&#8964;</div>}
              </Link>
              {mainItem.childItems.nodes.length !== 0 ? (
                <ul>
                  {mainItem.childItems.nodes.map(childItem => (
                    <li key={childItem.id}>
                      <Link to={childItem.url} activeClassName="nav-active">
                        {childItem.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              ) : null}
            </li>
          ) : null,
        )}
        <li>
          <Link to="/contact-us" activeClassName="nav-active">
            Contact Us
          </Link>
        </li>
      </ul>
    </NavigationWrapper>
  )
}
